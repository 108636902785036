import { FirebaseOptions, initializeApp } from 'firebase/app';

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// https://firebase.google.com/docs/web/setup
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyA3tFaybm5E26wrOZHLKWQ_4Ci3pLVUWkk',
  authDomain: 'ruokalista-9642f.firebaseapp.com',
  projectId: 'ruokalista-9642f',
  storageBucket: 'ruokalista-9642f.appspot.com',
  messagingSenderId: '108234427135',
  appId: '1:108234427135:web:ffd7e37678ac217c9d9aca'
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);

export const auth = getAuth(app);
